import {useEffect, useState} from 'react'
import api from "../../Request"
import Header from "../header"
import { useForm } from 'react-hook-form';


function Contact () {
  const [publicPath, setPublicPath] = useState('')
  const [profileInfo, setProfileInfo] = useState([])
  const [showPage, setShowPage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (data) => {
    api.requests('contact').create(data).then((res) => {
      const {message} = res.data.data
      setSuccessMessage(message)
    });
    setTimeout(function () {
      setSuccessMessage('')
    }, 5000);
  }
  const getProfileInfo = () => {
    if(profileInfo.length === 0){
      setShowPage('')

      api.requests('configration').fetchAll({page:'contact'}).then((res) => {
        const {config, path} = res.data.data
        setProfileInfo(config)
        setPublicPath(path)
      });
      setTimeout(function () {
        setShowPage('section-show')
      }, 100);
    }
    
  }
  useEffect(getProfileInfo, [])
    return (
<>
<Header page={'contact'} profileInfo={profileInfo}/>
<section id="contact" className={showPage+" contact"}>
    <div className="container">

      <div className="section-title">
        <h2>Contact</h2>
        <p>Contact Me</p>
      </div>

      <div className="row mt-2">

        <div className="col-md-6 d-flex align-items-stretch">
          <div className="info-box">
            <i className="bx bx-map"></i>
            <h3>My Address</h3>
            <p>{profileInfo.ADDRESS +', '+ profileInfo.STATE +', '+ profileInfo.COUNTRY+', '+ profileInfo.ZIP_CODE}</p>
          </div>
        </div>

        <div className="col-md-6 mt-4 mt-md-0 d-flex align-items-stretch">
          <div className="info-box">
            <i className="bx bx-share-alt"></i>
            <h3>Social Profiles</h3>
            <div className="social-links">
            {profileInfo.TWITTER_URL && (
              <a href={profileInfo.TWITTER_URL} className="twitter" target="_blank"><i className="bi bi-twitter"></i></a>
             )}
             {profileInfo.FACEBOOK_URL && (
            <a href={profileInfo.FACEBOOK_URL} className="facebook"><i className="bi bi-facebook"></i></a>
            )}
            {profileInfo.INSTAGRAM_URL && (
            <a href={profileInfo.INSTAGRAM_URL} className="instagram"><i className="bi bi-instagram"></i></a>
            )}
            {profileInfo.LINKED_IN_URL && (
            <a href={profileInfo.LINKED_IN_URL} className="linkedin"><i className="bi bi-linkedin"></i></a>
            )}

            </div>
          </div>
        </div>

        <div className="col-md-6 mt-4 d-flex align-items-stretch">
          <div className="info-box">
            <i className="bx bx-envelope"></i>
            <h3>Email Me</h3>
            <p>{profileInfo.EMAIL}</p>
          </div>
        </div>
        <div className="col-md-6 mt-4 d-flex align-items-stretch">
          <div className="info-box">
            <i className="bx bx-phone-call"></i>
            <h3>Call Me</h3>
            <p>{profileInfo.MOBILE}</p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="php-email-form mt-4">

        <div className="row">
          <div className="col-md-6 form-group">
            <input type="text" className="form-control" id="name" placeholder="Full name" {...register("fullname", {required: true, maxLength: 80})} />
            {errors.fullname && <p className='error'>Name field required</p>}

          </div>
          <div className="col-md-6 form-group mt-3 mt-md-0">
          <input
                placeholder='Email'
                className="form-control"
                type="email"
                {...register("email", 
                { 
                    required: true,  
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
                })}
            />
          {errors.email && <p className='error'>Email field required</p>}
          </div>
        </div>
        <div className="row">
        <div className="form-group col-md-6 mt-3">
         <input type="text" className="form-control" id="company_name" placeholder="Company name" {...register("company", {required: true})} />
          {errors.company && <p className='error'>Company field required</p>}
        </div>
        <div className="form-group col-md-6 mt-3">
         <input type="text" className="form-control" id="company_location" placeholder="Company location" {...register("company_location", {required: true})} />
          {errors.company_location && <p className='error'>Company location field required</p>}
        </div>
        </div>
        <div className="form-group mt-3">
          <textarea className="form-control" name="message" rows="5" placeholder="Message"  {...register("message", {required: true})}></textarea>
          {errors.message && <p className='error'>Message field required</p>}

        </div>
      
        <div className="my-2">
        {successMessage && (
          <div className="sent-message">{successMessage}</div>
          )}
        </div>
       
        <div className="text-center"><button type="submit" disabled={successMessage !== ""}>Send Message</button></div>
      </form>

    </div>
  </section>

</>
    
    )
 }
 export default Contact