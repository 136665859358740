import {useEffect, useState} from 'react'
import api from "../../Request"
import Header from "../header"

function Resume () {
  const [publicPath, setPublicPath] = useState('')
  const [profileInfo, setProfileInfo] = useState([])
  const [showPage, setShowPage] = useState('')

  const getProfileInfo = () => {
    setShowPage('')
    if(profileInfo.length === 0){
      api.requests('configration').fetchAll({page:'resume'}).then((res) => {
        const {config, path} = res.data.data
        setProfileInfo(config)
        setPublicPath(path)
      });
      setTimeout(function () {
        setShowPage('section-show')
      }, 100);
    }
    
  }
  useEffect(getProfileInfo, [])
    return (
<>
<Header page={'resume'} profileInfo={profileInfo}/>
<section id="resume" className={showPage+" resume"}>
    <div className="container">

      <div className="section-title">
        <h2>Resume</h2>
        <p>{profileInfo.RESUME_SECTION_TITLE}</p>
      </div>

      <div className="row" dangerouslySetInnerHTML={{__html: profileInfo.RESUME_SECTION_CONTENT}} ></div>

    </div>
  </section>
</>
    
    )
 }
 export default Resume