import {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
 function Header (props) {
    const {page, profileInfo} = props
    
    const [bgImage, setBgImage] = useState('')
    const updateBgImage = () => {
        setBgImage(profileInfo.BG_IMAGE)
    }
    useEffect(updateBgImage, [profileInfo.BG_IMAGE])
    return (<>
      <style>{'body {background: #040404 url("'+bgImage+'") top right no-repeat}'}</style>
        <header id="header" className={page != 'home' ? 'header-top' : ''}>
        <div className="container">
        <h1>{profileInfo.NAME}</h1>
        {page === 'home' && (
            <>
          <h2>I'm a passionate <span>{profileInfo.PROFILE_TITLE}</span> from {profileInfo.COUNTRY}</h2>
          </>
        )}
          <nav id="navbar" className="navbar" >
            <ul>
              <li><Link className={(page == 'home' ? 'active' : 'nav-link') + '' } to="/">Home</Link></li>
              <li><Link className={(page == 'about' ? 'active' : 'nav-link') + '' } to="/about">About</Link></li>
              {/* <li><Link className={(page == 'resume' ? 'active' : 'nav-link') + '' } href="/resume">Resume</Link></li> */}
              <li><Link className={(page == 'technologies' ? 'active' : 'nav-link') + '' } to="/technologies">Technologies</Link></li>
              <li><Link className={(page == 'portfolio' ? 'active' : 'nav-link') + '' } to="/portfolio">Portfolio</Link></li>
              <li><Link className={(page == 'contact' ? 'active' : 'nav-link') + '' } to="/contact">Contact</Link></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {page == 'home' && (
          <div className="social-links">
             {profileInfo.TWITTER_URL && (
              <a href={profileInfo.TWITTER_URL} className="twitter" target="_blank"><i className="bi bi-twitter"></i></a>
             )}
             {profileInfo.FACEBOOK_URL && (
            <a href={profileInfo.FACEBOOK_URL} className="facebook"><i className="bi bi-facebook"></i></a>
            )}
            {profileInfo.INSTAGRAM_URL && (
            <a href={profileInfo.INSTAGRAM_URL} className="instagram"><i className="bi bi-instagram"></i></a>
            )}
            {profileInfo.LINKED_IN_URL && (
            <a href={profileInfo.LINKED_IN_URL} className="linkedin"><i className="bi bi-linkedin"></i></a>
            )}
          </div>
            )}
        </div>
      </header>
      </>
    )
 }
 export default Header