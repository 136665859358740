import {useEffect, useState} from 'react'
import api from "../../Request"
import Header from "../header"

function Technologies () {

  const [publicPath, setPublicPath] = useState('')
  const [profileInfo, setProfileInfo] = useState([])
  const [technologyInfo, setTechnologyInfo] = useState([])
  const [showPage, setShowPage] = useState('')

  const getProfileInfo = () => {
    setShowPage('')
    if(profileInfo.length === 0){
      api.requests('configration').fetchAll({page:'technologies'}).then((res) => {
        const {config, technologies, path} = res.data.data
        
        setProfileInfo(config)
        setTechnologyInfo(technologies)
        setPublicPath(path)
        setTimeout(function () {
          setShowPage('section-show')
        }, 100);
      });
    }
    
  }
  useEffect(getProfileInfo, [])
    return (
<>
<Header page={'technologies'} profileInfo={profileInfo} />

<section id="technologies" className={showPage+" services"}>
    <div className="container">

      <div className="section-title">
        <h2>Technologies</h2>
        <p>My Expertise</p>
      </div>

      <div className="row">
      {technologyInfo.map((info, key) => (
        <div key={key} className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div className="icon-box">
            <div className="icon"> <img  src={publicPath+'/technologies/'+info.image} width="30" className="testimonial-img" alt="" /></div>
            <h4>{info.name}</h4>
            <p>{info.content}</p>
          </div>
        </div>
      ))}
      </div>

    </div>
  </section>
</>
    
    )
 }
 export default Technologies