import {useEffect, useState} from 'react'
import Header from "../header"
import api from "../../Request"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, {Pagination, Autoplay } from "swiper";

SwiperCore.use([Pagination, Autoplay]);

function About () {

  const [publicPath, setPublicPath] = useState('')
  const [showPage, setShowPage] = useState('')
  const [profileInfo, setProfileInfo] = useState([])
  const [testimonialInfo, setTestimonialInfo] = useState([])
  const getProfileInfo = () => {
    setShowPage('')
    if(profileInfo.length === 0){
      api.requests('configration').fetchAll({page:'about'}).then((res) => {
        const {config, testimonial, path} = res.data.data
        
        setProfileInfo(config)
        setTestimonialInfo(testimonial)
        setPublicPath(path)
        setTimeout(function () {
          setShowPage('section-show')
        }, 100);
        
      });
    }
    
  }
  useEffect(getProfileInfo, [])

  const calculateAge = (dob) => { 
    var date  = new Date(dob);
    var diff_ms = Date.now() - date.getTime();
    var age_dt = new Date(diff_ms); 
  
    return Math.abs(age_dt.getUTCFullYear() - 1970);
}

    return (
<>
<Header page={'about'} profileInfo={profileInfo}/>
<section id="about" className={showPage+" about"}>
    <div className="about-me container">

      <div className="section-title">
        <h2>About</h2>
        <p>Learn more about me</p>
      </div>

      <div className="row">
        <div className="col-lg-4" data-aos="fade-right">
          <img src={profileInfo.ABOUT_SECTION_IMAGE} className="img-fluid" alt="" />
        </div>
        <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
          <h3>{profileInfo.ABOUT_SECTION_TITLE}</h3>
          <div className="row">
            <div className="col-lg-6">
              <ul>
                <li><i className="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>{profileInfo.DOB}</span></li>
                <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>{profileInfo.MOBILE}</span></li>
                <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>{profileInfo.STATE +', '+ profileInfo.COUNTRY}</span></li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul>
                <li><i className="bi bi-chevron-right"></i> <strong>Age:</strong> <span>{calculateAge(profileInfo.DOB)}</span></li>
                <li><i className="bi bi-chevron-right"></i> <strong>Email Address:</strong> <span>{profileInfo.EMAIL}</span></li>
                {profileInfo.LOOKING_FOR_CHANGE != 1 && (
                <li><i className="bi bi-chevron-right"></i> <strong>Looking for change:</strong> <span>Available</span></li>
                )}
              </ul>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: profileInfo.ABOUT_SECTION_CONTENT }}></div>
        </div>
      </div>

    </div>   
  

<div className="interests container">

      <div className="section-title">
        <h2>Interests</h2>
      </div>

      <div className="row">
      
      {profileInfo.PERSONAL_INTEREST && profileInfo.PERSONAL_INTEREST.split(',').map((info, key) => (
        <div className="col-lg-3 col-md-4 mt-4" key={key}>
          <div className="icon-box">
            <i className="ri-store-line"></i>
            <h3>{info}</h3>
          </div>
        </div>
         ))}
      </div>

    </div>
<div className="testimonials container">

      <div className="section-title">
        <h2>LinkedIn Recommendation</h2>
      </div>

      <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
      <Swiper
      spaceBetween={0}
      id="customSwiper"
      slidesPerView={3}
      pagination
      loop={true}
      autoplay={{
          delay: 2000
      }}
    >
      {testimonialInfo.map((info, key) => (
        <SwiperSlide key={key} className="swiper-wrapper">
          <div className="testimonial-item">
          <p>
              <i className="bx bxs-quote-alt-left quote-icon-left"></i>
              {info.content}
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
              <img  src={publicPath+'/testimonials/'+info.image} className="testimonial-img" alt="" />
              <h3>{info.name}</h3>
              <h4>{info.designation}</h4>
          
          </div>
        </SwiperSlide>
      ))}
      
    </Swiper>
    </div>
    </div>
  </section>
</>
    
    )
 }
 export default About