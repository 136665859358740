import React from 'react';


import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';
import App from './App';
import About from './app/Pages/About';
import Resume from './app/Pages/Resume';
import Technologies from './app/Pages/Technologies';
import Portfolio from './app/Pages/Portfolio';
import Contact from './app/Pages/Contact';
import Home from './app/Pages/Home';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
           <Route path="about" element={<About />} />
           <Route path="resume" element={<Resume />} />
           <Route path="technologies" element={<Technologies />} />
           <Route path="portfolio" element={<Portfolio />} />
           <Route path="contact" element={<Contact />} />
        
          {/* <Route path="*" element={<NoPage />} /> */} */}
        </Route>
      </Routes>
    </BrowserRouter>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
