import axios from "axios";
let headers = {
    'Content-Type': 'application/json',
  }
export default {
    requests(baseUrl) {
      const url = 'https://mayankbindra.com/backend/api'  + '/' +  baseUrl;
        return {
            fetchAll: (params) => axios.get(url, {params, headers: headers}),
            fetchById: (id, params) => axios.get(url +'/'+ id, {params, headers: headers}),
            fetchByParms: (id, params) => axios.post(url +'/'+ id, {params, headers: headers}),
            create: newRecord => axios.post(url, newRecord, {headers: headers}),
            update: (id, updateRecord) => axios.put(url +'/'+  id, updateRecord, {headers: headers}),
            delete: (id, params) => axios.delete(url +'/'+  id, {params, headers: headers}),
        }
    }
}
