import {useEffect, useState} from 'react'
import Header from "../header"
import api from "../../Request"

function Home () {

    const [profileInfo, setProfileInfo] = useState([])
    const getProfileInfo = () => {
      if(profileInfo.length === 0){
        api.requests('configration').fetchAll({page:'home'}).then((res) => {
          const {config} = res.data.data
          setProfileInfo(config)
        });
      }
      
    }
    useEffect(getProfileInfo, [])
    return (
<>
<Header page={'home'} profileInfo={profileInfo}/>
</>
    
    )
 }
 export default Home