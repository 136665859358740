import {useEffect, useState, useRef} from 'react'
import api from "../../Request"
import Header from "../header"
import Isotope from "isotope-layout";
function Portfolio () {
  const [publicPath, setPublicPath] = useState('')
  const [profileInfo, setProfileInfo] = useState([])
  const [technologyInfo, setTechnologyInfo] = useState([])
  const [portfolioInfo, setPortfolioInfo] = useState([])
  const [showPage, setShowPage] = useState('')

  const getProfileInfo = () => {
    if(profileInfo.length === 0){
      setShowPage('')
      api.requests('configration').fetchAll({page:'portfolio'}).then((res) => {
        const {config, technologies, portfolio, path} = res.data.data
        
        setProfileInfo(config)
        setTechnologyInfo(technologies)
        setPortfolioInfo(portfolio)
        setPublicPath(path)
      });
      setTimeout(function () {
        setShowPage('section-show')
      }, 100);
    }
    
  }
  useEffect(getProfileInfo, [])

  const isotope = useRef()
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState('*')
  const handleFilterKeyChange = (key) => {
    setFilterKey(key)
    console.log('sdd')
  }
  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope('.filter-container', {
      itemSelector: '.filter-item',
      layoutMode: 'fitRows',
    })
    // cleanup
    return () => isotope.current.destroy()
  }, [filterKey])

  // handling filter key change
  useEffect(() => {
    filterKey === '*'
      ? isotope.current.arrange({filter: `*`})
      : isotope.current.arrange({filter: `.${filterKey}`})
  }, [filterKey])

 
  const printArr = (arr) => {
    let str = "";
      for (let item of arr) {
        str += item.name.replaceAll(/\s/g,'') + " ";
      }
      return str;
    }
  
    return (
<>
<Header page={'portfolio'} profileInfo={profileInfo} />

<section id="portfolio" className={showPage+" portfolio"}>
    <div className="container">

      <div className="section-title">
        <h2>Portfolio</h2>
        <p>My Works</p>
      </div>

      <div className="row">
        <div className="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
          <li onClick={() => {handleFilterKeyChange('*')}}>All</li>
          {technologyInfo.map((info, key) => (
            <li key={key} onClick={() => {handleFilterKeyChange(info.name.replaceAll(/\s/g,''))}}>{info.name}</li>
          ))}
          </ul>
        </div>
      </div>

      <div className="row portfolio-container filter-container">
      {portfolioInfo.map((info, key) => (
        
        <div key={key} className={'col-lg-4 col-md-6 portfolio-item filter-item '+ (printArr(info.technologies))}>
          <div className="portfolio-wrap">
            <img  src={publicPath+'/portfolio/'+info.image} className="img-fluid" alt="" />
            <div className="portfolio-info">
              <h4>{info.title}</h4>
              <p>{printArr(info.technologies)}</p>
              <div className="portfolio-links">
                <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i className="bx bx-plus"></i></a>
                <a href="portfolio-details.html" data-gallery="portfolioDetailsGallery" data-glightbox="type: external" className="portfolio-details-lightbox" title="Portfolio Details"><i className="bx bx-link"></i></a>
              </div>
            </div>
          </div>
         
                   

        </div>
        ))}

      </div>

    </div>
  </section>
</>
    
    )
 }
 export default Portfolio
